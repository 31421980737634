import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon.js";
import Place from "@material-ui/icons/Place";
import CardBody from "components/Card/CardBody";
import Table from "components/Table/Table.js";
import ReactFilestack from "filestack-react";
import FormLabel from "@material-ui/core/FormLabel";
import jwt_decode from "jwt-decode";
import Dvr from "@material-ui/icons/Dvr";

// import jwt_decode from "jwt-decode";
import Button from "components/CustomButtons/Button.js";
import Close from "@material-ui/icons/Close";
import Delete from "@material-ui/icons/Delete";
import CustomInput from "components/CustomInput/CustomInput.js";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import Forward from "@material-ui/icons/Forward";
import Danger from "components/Typography/Danger";
import Success from "components/Typography/Success.js";
import Datetime from "react-datetime";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
//Modal
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Snackbar from "components/Snackbar/Snackbar.js";
import CardFooter from "components/Card/CardFooter";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ReadTransformador(props) {
  const classes = useStyles();
  const API_KEY = "AWevYlMNkS7KJFbiRivemz";
  const options = {
    maxFiles: 5,
    fromSources: ["local_file_system", "audio"],
  };

  const [trabajo, setTrabajo] = React.useState({
    comentario: "",
    fechaCambio: "",
    contratistaNombre: "",
    contratistaEmpresa: "",
  });
  const [nameDescription, setNameDescription] = useState("");
  const [preDeleteId, setPreDeleteId] = useState();
  const [eliminarNotaModal, setEliminarNotaModal] = useState(false);
  const [transformador, setTransformador] = useState();
  const [alertModal, setAlertModal] = useState(false);
  const [trabajosModal, setTrabajosModal] = useState(false);
  const [panelModal, setPanelModal] = useState(false);
  const [urlFile, setUrlFile] = useState([]);
  const [urlFileError, setUrlFileError] = useState();
  const [urlArchivo, setUrlArchivo] = useState();
  const [urlArchivoError, setUrlArchivoError] = useState();
  const [txtNotificacion, setTxtNotificacion] = useState("");
  const [tc, setTC] = useState(false);
  const [controlCambios, setControlCambios] = useState([]);
  const [codigoPanel, setCodigoPanel] = useState("");
  const [notaModal, setNotaModal] = useState(false);
  const [notas, setNotas] = useState([]);
  const [textNote, setTextNote] = useState("");
  const [filesModal, setFilesModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [errorDescription, setErrorDescription] = useState("");
  const [eliminarFileModal, setEliminarFileModal] = useState(false);
  const [servicioSanitario, setServiciosSanitario] = useState();
  const [variablesModal, setVariablesModal] = useState(false);
  const [vartoEdit, setVartoEdit] = useState({});
  const [vartoEditModal, setVartoEditModal] = useState(false);
  const fechaActual = new Date();
  const [selectedDate, setSelectedDate] = useState(fechaActual);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const fetchData = async () => {
    const result = await axios.get(
      // `http://localhost:5005/api/limpieza/readserviciosanitario/${props.match.params.id}`
      `https://epstool.com/api/limpieza/readserviciosanitario/${props.match.params.id}`
    );
    setServiciosSanitario(result.data);
    setControlCambios(result.data.informes);
    setNotas(result.data.notas);
    setFiles(result.data.files);
  };

  // CARGA INICIAL
  useEffect(() => {
    fetchData();
  }, []);

  const handleFrom = (e) => {
    setSelectedDate(e);
  };

const fetchDataInformes = async () => {
      setOpen(true);
      // setAuthToken(localStorage.jwtToken);
      const result = await axios.post(
        // `http://localhost:5005/api/limpieza/readinformeserviciosanitario/${props.match.params.id}`,
        `https://epstool.com/api/limpieza/readinformeserviciosanitario/${props.match.params.id}`,
        // "https://epstool.com/api/limpieza/readallserviciossanitarios",
        {
          fecha: selectedDate,
        }
      );
      // console.log(result.data);
      setControlCambios(result.data);
      setOpen(false);
    };
  const showNotification = () => {
    setTC(true);
    setTimeout(function () {
      setTC(false);
    }, 6000);
  };
  //Editar facilidades
  const editarFacilidades = async (event) => {
    let arrVar = servicioSanitario.facilidades;
    const index = arrVar.map((object) => object._id).indexOf(vartoEdit._id);
    arrVar[index] = vartoEdit;
    // console.log(arrVar);
    axios
      .put(
        // `http://localhost:5005/api/limpieza/updateserviciosanitario/${servicioSanitario._id}`,
        // `https://epstool.com/api/limpieza/updateserviciosanitario/${servicioSanitario._id}`,
        arrVar
      )
      .then((res) => {
        servicioSanitario.facilidades = arrVar;
        setTxtNotificacion("Facilidades editadas con éxito");
        setServiciosSanitario(servicioSanitario);
        showNotification();
        setVartoEditModal(false);
      });
  };

  const habilitarServicioSanitario = async (event) => {
    let changeStatus = { estado: 0 };
    servicioSanitario.estado === 1
      ? (changeStatus.estado = 3)
      : servicioSanitario.estado === 3
      ? (changeStatus.estado = 1)
      : (changeStatus.estado = 0);
    console.log(changeStatus);
    if (changeStatus.estado > 0) {
      axios
        .put(
          `https://epstool.com/api/limpieza/habilitarserviciosanitario/${servicioSanitario._id}`,
          // `http://localhost:5005/api/limpieza/habilitarserviciosanitario/${servicioSanitario._id}`,
          changeStatus
        )
        .then((res) => {
          console.log(res.data);
          servicioSanitario.estado = changeStatus.estado;
          setTxtNotificacion("Facilidades editadas con éxito");
          setServiciosSanitario(servicioSanitario);
          showNotification();
        });
    }
  };
  // GUARDAR NOTA
  const handleNotaSubmit = async (event) => {
    event.preventDefault();
    const decoded = jwt_decode(localStorage.jwtToken);
    const notaToSave = {
      nota: textNote,
      userName: decoded.name,
      userCompany: decoded.company,
    };
    await axios
      .post(
        `https://epstool.com/api/transformador/nota/${transformador._id}`,
        // `http://localhost:5000/api/transformador/nota/${transformador._id}`,
        notaToSave
      )
      .then((res) => {
        setNotas(res.data.transformador.notas);
        setTxtNotificacion("Nota guardada con éxito");
        setTextNote("");
        setNotaModal(false);
        showNotification();
      })
      .catch((error) => {
        console.log(error);
        setTxtNotificacion("Se ha producido un error");
        showNotification();
      });
  };

  // Eliminar Nota
  const deleteNota = async (idNota, idTrafo) => {
    await axios
      .delete(
        // `https://epstool.com/api/polos/heredia/${codigoPolo}/${zona}/${id}/${tipo}`
        // `http://localhost:5000/api/transformador/nota/${idTrafo}/${idNota}`
        `https://epstool.com/api/transformador/nota/${idTrafo}/${idNota}`
      )
      // .delete(`/api/polos/${codigoPolo}/${zona}/${id}/${tipo}`)
      .then((res) => {
        setNotas(res.data.notas);
        setEliminarNotaModal(false);
        setPreDeleteId("");
        setTxtNotificacion("Nota eliminada con éxito");
        showNotification();
      })
      .catch((error) => {
        console.log(error);
        setTxtNotificacion("Se ha producido un error");
        showNotification();
      });
  };

  // GUARDAR Trabajo
  const handleTrabajoSubmit = async (event) => {
    event.preventDefault();
    const decoded = jwt_decode(localStorage.jwtToken);
    let filesToSave = [];
    urlFile.forEach((archivo) => {
      let archivoToSave = {
        url: archivo.url,
        fileName: archivo.filename,
        type: archivo.mimetype,
      };
      filesToSave.push(archivoToSave);
    });
    const trabajoToSave = {
      comentario: trabajo.comentario,
      fechaCambio: trabajo.fechaCambio,
      contratistaNombre: trabajo.contratistaNombre,
      contratistaEmpresa: trabajo.contratistaEmpresa,
      filesCambios: filesToSave,
      userName: decoded.name,
      userCompany: decoded.company,
    };
    await axios
      .post(
        `https://epstool.com/api/transformador/trabajo/${transformador._id}`,
        // `http://localhost:5000/api/transformador/trabajo/${transformador._id}`,
        trabajoToSave
      )
      .then((res) => {
        // setNotas(res.data.polo.notas);
        setControlCambios(res.data.transformador.controlCambios);
        setTxtNotificacion("Trabajo guardado con éxito");
        setTrabajosModal(false);
        showNotification();
      })
      .catch((error) => {
        setTxtNotificacion("Se ha producido un error");
        showNotification();
      });
  };

  //Manejo de archivos
  const handleFileSubmit = async (event) => {
    event.preventDefault();
    setUrlArchivoError();
    setErrorDescription();
    let flag = 0;
    !nameDescription
      ? setErrorDescription("Debe agregar un nombre al archivo")
      : (flag = flag + 1);
    !urlArchivo
      ? setUrlArchivoError("Debe adjuntar un documento o imagen")
      : (flag = flag + 1);

    if (flag === 2) {
      const decoded = jwt_decode(localStorage.jwtToken);
      const fileToSave = {
        url: urlArchivo.url,
        fileName: urlArchivo.filename,
        fileDescription: nameDescription,
        type: urlArchivo.mimetype,
        userName: decoded.name,
        userCompany: decoded.company,
      };
      await axios
        .post(
          `https://epstool.com/api/transformador/file/${transformador._id}`,
          fileToSave
        )
        // .post(
        //   `http://localhost:5000/api/transformador/file/${transformador._id}`,
        //   fileToSave
        // )
        // .post(`/api/maquinas/file/${equipo._id}`, fileToSave)
        .then((res) => {
          setFiles(res.data.transformador.files);
          setTxtNotificacion("Archivo guardado con éxito");
          setNameDescription("");
          setUrlArchivo("");
          setFilesModal(false);
          showNotification();
        })
        .catch((error) => {
          setTxtNotificacion("Se ha producido un error");
          showNotification();
        });
    }
  };

  // Eliminar Archivo
  const deleteFile = async (idArchivo, idTrafo) => {
    await axios
      .delete(
        // `https://epstool.com/api/polos/heredia/${codigoPolo}/${zona}/${id}/${tipo}`
        // `http://localhost:5000/api/transformador/file/${idTrafo}/${idArchivo}`
        `https://epstool.com/api/transformador/file/${idTrafo}/${idArchivo}`
      )
      // .delete(`/api/polos/${codigoPolo}/${zona}/${id}/${tipo}`)
      .then((res) => {
        setFiles(res.data.files);
        setEliminarFileModal(false);
        setPreDeleteId("");
        setTxtNotificacion("Archivo eliminado con éxito");
        showNotification();
      })
      .catch((error) => {
        setTxtNotificacion("Se ha producido un error");
        showNotification();
      });
  };

  return (
    <div>
      <GridContainer>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <Place />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {servicioSanitario
                  ? `Servicio ${servicioSanitario.nombre} - ${servicioSanitario.tipo}`
                  : ""}
              </h4>
            </CardHeader>
            <CardBody>
              <br></br>
              <GridContainer>
                <GridItem xs={12}>
                  <strong>Ubicación:</strong>
                </GridItem>
              </GridContainer>
              <br></br>
              <GridContainer>
                <GridItem xs={12} sm={4}>
                  <p>
                    {servicioSanitario
                      ? servicioSanitario.nombre
                        ? `Detalle: ${servicioSanitario.nombre}`
                        : "Detalle: No hay registro"
                      : null}
                  </p>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <p>
                    {servicioSanitario
                      ? servicioSanitario.ubicacion.edificio
                        ? `Edificio: ${servicioSanitario.ubicacion.edificio}`
                        : "Edificio: No hay registro"
                      : null}
                  </p>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <p>
                    {servicioSanitario
                      ? servicioSanitario.ubicacion.nivel
                        ? `Nivel: ${servicioSanitario.ubicacion.nivel}`
                        : "Nivel: No hay registro"
                      : null}
                  </p>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <p>
                    {servicioSanitario
                      ? servicioSanitario.detalle
                        ? `Especificaciones: ${servicioSanitario.detalle}`
                        : ""
                      : null}
                  </p>
                </GridItem>
              </GridContainer>
              <br></br>
              <GridContainer>
                <GridItem xs={12}>
                  <strong>Facilidades:</strong>
                </GridItem>
              </GridContainer>
              <br></br>
              <GridContainer>
                {servicioSanitario
                  ? servicioSanitario.facilidades.map((servicio) => (
                      <GridItem xs={12} sm={4}>
                        <p>
                          {servicio.nombre}: {servicio.cantidad}
                        </p>
                      </GridItem>
                    ))
                  : null}
              </GridContainer>
            </CardBody>
            <CardFooter>
              <GridContainer>
                <GridItem xs={12}>
                  <GridContainer>
                    <GridItem xs={12} sm={4}>
                      {servicioSanitario && servicioSanitario.estado === 1 ? (
                        <Button
                          size="sm"
                          fullWidth
                          className={classes.cardButtonTitle}
                          color="success"
                          // onClick={() => {
                          //   setCambiarModal(true);
                          // }}
                          onClick={() => habilitarServicioSanitario()}
                        >
                          Colocar como fuera de edificio
                        </Button>
                      ) : servicioSanitario &&
                        servicioSanitario.estado === 2 ? (
                        <Button
                          disabled
                          size="sm"
                          fullWidth
                          className={classes.cardButtonTitle}
                          color="success"
                          // onClick={() => {
                          //   setCambiarModal(true);
                          // }}
                          onClick={() => habilitarServicioSanitario()}
                        >
                          Colocar como fuera de edificio
                        </Button>
                      ) : servicioSanitario &&
                        servicioSanitario.estado === 3 ? (
                        <Button
                          size="sm"
                          fullWidth
                          className={classes.cardButtonTitle}
                          color="success"
                          // onClick={() => {
                          //   setCambiarModal(true);
                          // }}
                          onClick={() => habilitarServicioSanitario()}
                        >
                          Habilitar
                        </Button>
                      ) : null}
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      <Button
                        size="sm"
                        fullWidth
                        className={classes.cardButtonTitle}
                        color="success"
                        onClick={() => {
                          setVariablesModal(true);
                        }}
                      >
                        Editar
                      </Button>
                    </GridItem>
                    {/* <GridItem xs={12} sm={4}>
                      <Button
                        size="sm"
                        fullWidth
                        className={classes.cardButtonTitle}
                        color="danger"
                        // onClick={() => {
                        //   setEliminarModal(true);
                        // }}
                      >
                        Eliminar
                      </Button>
                    </GridItem> */}
                  </GridContainer>
                  <GridContainer>
                    {/* 
                    <GridItem xs={12} sm={4}>
                      <Button
                        size="sm"
                        fullWidth
                        className={classes.cardButtonTitle}
                        color="success"
                        onClick={() => {
                          setPanelModal(true);
                        }}
                      >
                        Indexar Tablero
                      </Button>
                    </GridItem>
                     */}
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      {/* Espacio de control de cambios */}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader>
              <GridContainer>
              <GridItem xs={12} sm={3}>
                <h4 className={classes.cardTitle}>Control de limpieza</h4>
              </GridItem>
              <GridItem xs={12} sm={3}>
              <Datetime
                onChange={handleFrom}
                timeFormat={false}
                inputProps={{ placeholder: "Cambiar fecha" }}
              />
            </GridItem>
            <GridItem xs={12} sm={3}>
              <Button
                color="info"
                size="sm"
                onClick={() => fetchDataInformes()}
              >
                Cambiar fecha
              </Button>
            </GridItem>
              </GridContainer>
             
            </CardHeader>
            <CardBody>
              {controlCambios.length === 0 ? (
                "No hay cambios registrados"
              ) : (
                  <Table
                    tableHead={[
                      "Turno",
                      "Inicio",
                      "Finalización",
                      "Firma",
                      "Comentario",
                    ]}
                    tableData={controlCambios.map((informe) => [
                      informe.turno,
                      new Date(informe.horaComienzo * 1000).toLocaleString(),
                      new Date(informe.horaFinal * 1000).toLocaleString(),
                      informe.firma,
                      informe.comentario,
                      // <Button
                      //   justIcon
                      //   color="success"
                      //   size="sm"
                      //   onClick={() => {
                      //     props.history.push(`../informes/${informe._id}`);
                      //   }}
                      // >
                      //   <Dvr />
                      // </Button>,
                    ])}
                    customCellClasses={[
                      classes.center,  
                      classes.center,
                      classes.center, 
                      classes.center, 
                      classes.center,
                      classes.center,

                      // classes.right,
                      // classes.right,
                      // classes.right,
                      // classes.right,
                    ]}
                    customClassesForCells={[0, 6]}
                    customHeadCellClasses={[
                      classes.center,  
                      classes.center,
                      classes.center, 
                      classes.center, 
                      classes.center,
                      classes.center,
                      
                      // classes.center,
                      // classes.right,
                      // classes.right,
                      // classes.right,
                      // classes.right,
                    ]}
                    customHeadClassesForCells={[0, 6]}
                  />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/* Espacio de control de Notas */}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader>
              <GridContainer>
                <GridItem xs={12} sm={12} md={9}>
                  <h4 className={classes.cardTitle}>Notas</h4>
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <Button
                    size="sm"
                    className={classes.cardButtonTitle}
                    color="success"
                    onClick={() => {
                      setNotaModal(true);
                    }}
                  >
                    Añadir nota
                  </Button>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              {notas.length === 0
                ? "No hay notas registradas"
                : notas.map((nota) => (
                    <GridContainer key={nota._id}>
                      <GridItem xs={10}>
                        <strong>
                          Nota del dia{" "}
                          {new Date(nota.fecha * 1000).toLocaleString()}:
                        </strong>
                      </GridItem>
                      <GridItem xs={2}>
                        <Button
                          justIcon
                          color="danger"
                          size="sm"
                          onClick={() => {
                            setPreDeleteId(nota._id);
                            setEliminarNotaModal(true);
                          }}
                        >
                          <Delete />
                        </Button>
                      </GridItem>
                      <GridItem xs={12}>
                        <p>{nota.comentario}.</p>
                      </GridItem>
                      <GridItem xs={12}>
                        <p>
                          Realizado por {nota.userName} - {nota.userCompany}
                        </p>
                      </GridItem>
                    </GridContainer>
                  ))}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/* Espacio de control de archivos */}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader>
              <GridContainer>
                <GridItem xs={12} md={9}>
                  <h4 className={classes.cardTitle}>Archivos</h4>
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <Button
                    size="sm"
                    className={classes.cardButtonTitle}
                    color="success"
                    onClick={() => {
                      setFilesModal(true);
                    }}
                  >
                    Añadir archivo
                  </Button>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              {files.length === 0
                ? "No se registran archivos"
                : files.map((file) => (
                    <GridContainer key={file._id}>
                      <GridItem xs={10}>
                        <strong>
                          Archivo almacendo el dia{" "}
                          {new Date(file.fecha * 1000).toLocaleString()}:
                        </strong>
                      </GridItem>
                      <GridItem xs={2}>
                        <Button
                          justIcon
                          color="danger"
                          size="sm"
                          onClick={() => {
                            setPreDeleteId(file._id);
                            setEliminarFileModal(true);
                          }}
                        >
                          <Delete />
                        </Button>
                      </GridItem>
                      <GridItem xs={12}>
                        <strong>
                          <a href={file.url} target="_blank">
                            Ver archivo {file.fileDescripcion}
                          </a>
                        </strong>
                      </GridItem>
                      <GridItem xs={12}>
                        <p>
                          Realizado por {file.userName} - {file.userCompany}
                        </p>
                      </GridItem>
                    </GridContainer>
                  ))}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/* MODAL MODULO EN DESARROLLO */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={alertModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setAlertModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setAlertModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Alerta</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <h3>Este modulo se encuentra en desarrollo </h3>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={() => setAlertModal(false)} color="success" block>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL EDITAR TRANSFORMADOR */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={trabajosModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setTrabajosModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setTrabajosModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Registro de trabajo</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={12} sm={4}>
              <FormLabel className={classes.labelCustomHorizontal}>
                Comentario
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={8}>
              <CustomInput
                multiline
                id="comentario"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  multiline: true,
                  onChange: (e) => {
                    setTrabajo({
                      ...trabajo,
                      comentario: e.target.value,
                    });
                  },
                  type: "text",
                  value: trabajo.comentario,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4}>
              <FormLabel className={classes.labelCustomHorizontal}>
                Responsable
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={8}>
              <CustomInput
                multiline
                id="Responsable"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (e) => {
                    setTrabajo({
                      ...trabajo,
                      contratistaNombre: e.target.value,
                    });
                  },
                  type: "text",
                  value: trabajo.contratistaNombre,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4}>
              <FormLabel className={classes.labelCustomHorizontal}>
                Empresa
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={8}>
              <CustomInput
                multiline
                id="empresa"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (e) => {
                    setTrabajo({
                      ...trabajo,
                      contratistaEmpresa: e.target.value,
                    });
                  },
                  type: "text",
                  value: trabajo.contratistaEmpresa,
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <ReactFilestack
                apikey={API_KEY}
                // onSuccess={(result) => console.log(result)}
                onSuccess={(result) => setUrlFile(result.filesUploaded)}
                onError={(error) =>
                  setUrlFileError(
                    "Se ha producido un error, porfavor intentarlo nuevamente"
                  )
                }
                actionOptions={options}
                customRender={({ onPick }) => (
                  <div>
                    <Button fullWidth color="info" onClick={onPick}>
                      Cargar archivo
                    </Button>
                    {urlFile[0] ? (
                      <Success>
                        <p> Archivo cargado </p>
                      </Success>
                    ) : null}
                    {urlFileError ? (
                      <Danger>
                        <p>{urlFileError}</p>
                      </Danger>
                    ) : null}
                  </div>
                )}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={handleTrabajoSubmit} color="success">
            Guardar
          </Button>
          <Button onClick={() => setTrabajosModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL EDITAR TRANSFORMADOR */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={panelModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setPanelModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setPanelModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Registro de trabajo</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={12}>
              <FormLabel className={classes.labelCustomHorizontal}>
                Código de panel
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={8}>
              <CustomInput
                multiline
                id="codPanel"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (e) => {
                    setCodigoPanel(e.target.value);
                  },
                  type: "text",
                  value: codigoPanel,
                }}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={handleTrabajoSubmit} color="success">
            Guardar
          </Button>
          <Button onClick={() => setPanelModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      {/* MODAL NOTA */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={notaModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setNotaModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setNotaModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Crear Nota</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <CustomInput
            multiline
            labelText="Escribir comentario"
            id="textNote"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              multiline: true,
              onChange: (event) => {
                setTextNote(event.target.value);
              },
              type: "text",
              value: textNote,
            }}
          />
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={handleNotaSubmit} color="success">
            Guardar
          </Button>
          <Button onClick={() => setNotaModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      {/* MODAL Eliminar nota */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={eliminarNotaModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setEliminarNotaModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setEliminarNotaModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Alerta</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <h3>
            ¿Está seguro de eliminar esta nota? Esta acción no puede deshacerse.
          </h3>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => deleteNota(preDeleteId, transformador._id)}
            color="success"
          >
            Eliminar
          </Button>
          <Button
            onClick={() => setEliminarNotaModal(false)}
            color="danger"
            simple
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      {/* MODAL ARCHIVO */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={filesModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setFilesModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setFilesModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Agregar archivo</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <CustomInput
            multiline
            labelText="Escribir nombre de archivo"
            id="nameDescription"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              multiline: true,
              onChange: (event) => {
                setNameDescription(event.target.value);
              },
              type: "text",
              value: nameDescription,
            }}
          />
          {errorDescription ? (
            <Danger>
              <p> {errorDescription} </p>
            </Danger>
          ) : null}
          <ReactFilestack
            apikey={API_KEY}
            onSuccess={(result) => setUrlArchivo(result.filesUploaded[0])}
            onError={(error) =>
              setUrlFileError(
                "Se ha producido un error, porfavor intentarlo nuevamente"
              )
            }
            actionOptions={options}
            customRender={({ onPick }) => (
              <div>
                <Button fullWidth color="info" onClick={onPick}>
                  Cargar archivo
                </Button>
                {urlArchivo ? (
                  <Success>
                    <p> Archivo cargado </p>
                  </Success>
                ) : null}
                {urlArchivoError ? (
                  <Danger>
                    <p>{urlArchivoError}</p>
                  </Danger>
                ) : null}
              </div>
            )}
          />
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={handleFileSubmit} color="success">
            Guardar
          </Button>
          <Button onClick={() => setFilesModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL Eliminar archivo */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={eliminarFileModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setEliminarFileModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setEliminarFileModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Alerta</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <h3>
            ¿Está seguro de eliminar este archivo? Esta acción no puede
            deshacerse.
          </h3>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => deleteFile(preDeleteId, transformador._id)}
            color="success"
          >
            Eliminar
          </Button>
          <Button
            onClick={() => setEliminarFileModal(false)}
            color="danger"
            simple
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL LISTAR VARIABLES */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={variablesModal}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        // maxWidth={"lg"}
        onClose={() => setVariablesModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setVariablesModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Seleccionar variable</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {servicioSanitario
            ? servicioSanitario.facilidades.map((servicio) => (
                <GridContainer>
                  <GridItem xs={10}>
                    <p>
                      <strong> {servicio.nombre}:</strong> {servicio.cantidad}
                    </p>
                  </GridItem>
                  <GridItem xs={2}>
                    <Button
                      justIcon
                      color="success"
                      size="sm"
                      onClick={() => {
                        setVartoEdit(servicio);
                        setVariablesModal(false);
                        setVartoEditModal(true);
                      }}
                    >
                      <EditIcon />
                    </Button>
                  </GridItem>
                </GridContainer>
              ))
            : null}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => setVariablesModal(false)}
            color="danger"
            simple
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL Editar VARIABLE */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={vartoEditModal}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => setVartoEditModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setVartoEditModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{vartoEdit.nombre}</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={3}>
              {" "}
              <FormLabel className={classes.labelCustomHorizontal}>
                Cantidad:{" "}
              </FormLabel>{" "}
            </GridItem>
            <GridItem xs={9}>
              <CustomInput
                // labelText="Escribir comentario"
                id="varMin"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "number",
                  onChange: (event) => {
                    // vartoEdit.minimo = event.target.value;
                    vartoEdit.cantidad = Number(event.target.value);
                    setVartoEdit({ ...vartoEdit });
                  },
                  value: vartoEdit.cantidad ? vartoEdit.cantidad : 0,
                  // value: vartoEdit.minimo,
                }}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => {
              editarFacilidades();
            }}
            color="success"
          >
            Guardar
          </Button>
          <Button
            onClick={() => setVartoEditModal(false)}
            color="danger"
            simple
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        place="tc"
        color="success"
        icon={DoneAllIcon}
        message={txtNotificacion}
        open={tc}
        closeNotification={() => setTC(false)}
        close
      />
    </div>
  );
}
