import Buttons from "views/Components/Buttons.js";
import Dashboard from "views/Dashboard/Dashboard.js";
import GridSystem from "views/Components/GridSystem.js";
import Icons from "views/Components/Icons.js";
import Notifications from "views/Components/Notifications.js";
import Panels from "views/Components/Panels.js";
import SweetAlert from "views/Components/SweetAlert.js";
import Typography from "views/Components/Typography.js";
import CrearTransformador from "views/Transformadores/creartransformador.js";
import ReadTransformador from "views/Transformadores/readtransformador.js";
import UpdateTransformador from "views/Transformadores/updatetransformador.js";
import CrearServicioSanitario from "views/ServiciosSanitarios/crearServicioSanitario";
import GraficoTendencia from "views/Graficos/tendencia";
import ReadServicio from "views/ServiciosSanitarios/readServicioSanitario"
// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/transformador/:numerotransformador",
    name: "Leer",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: ReadTransformador,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/editar/:numerotransformador",
    name: "Editar",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: UpdateTransformador,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/serviciosanitario/:id",
    name: "Editar",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: ReadServicio,
    layout: "/admin",
  },
  // {
  //   path: "/creartransformador",
  //   name: "Crear Transformador",
  //   icon: DashboardIcon,
  //   component: CrearTransformador,
  //   layout: "/admin",
  // },
  {
    path: "/crearserviciosanitario",
    name: "Crear Servicio Sanitario",
    icon: DashboardIcon,
    component: CrearServicioSanitario,
    layout: "/admin",
  },
  {
    path: "/estadistica",
    name: "Estadistica",
    icon: DashboardIcon,
    component: GraficoTendencia,
    layout: "/admin",
  },
];
export default dashRoutes;
