import React, { useEffect, useState } from "react";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Forward from "@material-ui/icons/Forward";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { blue, red, yellow } from "@material-ui/core/colors";
import { green } from "@material-ui/core/colors";
import { grayColor } from "assets/jss/material-dashboard-pro-react";
import Datetime from "react-datetime";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const classes = useStyles();
  
  const [serviciosSanitarios, setServiciosSanitarios] = useState([]);
  const fechaActual = new Date();
  const hoyTimestamp = Math.floor(fechaActual.getTime() / 1000);
  const fechaFormateada = `${fechaActual.getDate()}/${
    fechaActual.getMonth() + 1
  }/${fechaActual.getFullYear()}`;
  const [selectedDate, setSelectedDate] = useState(fechaActual);

  const handleFrom = (e) => {
    setSelectedDate(e);
  };
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
 
  // CARGA DE LA RONDA
  const fetchDataTransformadores = async () => {
    setOpen(true);
    // setAuthToken(localStorage.jwtToken);
    const result = await axios.post(
      // "http://localhost:5005/api/limpieza/readallserviciossanitarios",
      "https://epstool.com/api/limpieza/readallserviciossanitarios",
      {
        fecha: selectedDate,
      }
    );
    // console.log(result.data);
    setServiciosSanitarios(result.data);
    setOpen(false);

  };

  // CARGA INICIAL
  useEffect(() => {
    fetchDataTransformadores();
  }, []);
  let tbServiciosSanitarios;
  Object.keys(serviciosSanitarios).length >= 1
    ? (tbServiciosSanitarios = serviciosSanitarios.map((servicio) => {
        return {
          nombre: servicio.nombre,
          tipo: servicio.tipo,
          edificio: servicio.ubicacion.edificio,
          numLimpiezas: servicio.informesHoy,
          tiempoPromedio: servicio.tiempoPromedio,
          estado:
            servicio.estado === 1
              ? "Habilitado"
              : servicio.estado === 2
              ? "Fuera de servicio"
              : servicio.estado === 3
              ? "En limpieza"
              : "Sin estado",
          ultimaLimpieza: servicio.ultimaLimpieza
            ? new Date(servicio.ultimaLimpieza * 1000).toLocaleString()
            : "",
          atencion:
            servicio.estado === 3 ? (
              <div>
                <FiberManualRecordIcon style={{ color: blue[500] }} />
              </div>
            ) : hoyTimestamp - servicio.ultimaLimpieza < 7200 ? (
              <div>
                <FiberManualRecordIcon style={{ color: green[500] }} />
              </div>
            ) : hoyTimestamp - servicio.ultimaLimpieza > 7200 ? (
              <div>
                <FiberManualRecordIcon style={{ color: red[500] }} />
              </div>
            ) : (
              <div>
                <FiberManualRecordIcon style={{ color: grayColor[1000] }} />
              </div>
            ),
          actions: (
            <div className="actions-right">
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  props.history.push(`serviciosanitario/${servicio._id}`);
                  // alert("Deshabilitado");
                }}
                color="success"
                className="edit"
              >
                <Forward />
              </Button>{" "}
            </div>
          ),
        };
      }))
    : (tbServiciosSanitarios = []);

  return (
    
    <GridContainer>
       <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card>
        <CardHeader>
          <GridContainer>
            <GridItem xs={12} sm={3}>
              Fecha {fechaFormateada}
            </GridItem>
            <GridItem xs={12} sm={3}>
              <Datetime
                onChange={handleFrom}
                timeFormat={false}
                inputProps={{ placeholder: "Cambiar fecha" }}
              />
            </GridItem>
            <GridItem xs={12} sm={3}>
              <Button
                color="info"
                size="sm"
                onClick={() => fetchDataTransformadores()}
              >
                Actualizar
              </Button>
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12}>
              <ReactTable
                columns={[
                  {
                    Header: "Ubicación",
                    accessor: "nombre",
                  },
                  {
                    Header: "Tipo",
                    accessor: "tipo",
                  },
                  {
                    Header: "Edificio",
                    accessor: "edificio",
                  },
                  {
                    Header: "# limpiezas",
                    accessor: "numLimpiezas",
                  },
                  {
                    Header: "Tiempo prom",
                    accessor: "tiempoPromedio",
                  },
                  {
                    Header: "Ult. Limp",
                    accessor: "ultimaLimpieza",
                  },

                  {
                    Header: "Atención",
                    accessor: "atencion",
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                  },
                ]}
                data={tbServiciosSanitarios}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
        
      </Card>
     
    </GridContainer>
  );
}
