import React, { useEffect, useState } from "react";
import axios from "axios";
import Chart from "react-apexcharts";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Forward from "@material-ui/icons/Forward";

// core components
import { Container, Paper, Typography } from "@material-ui/core";
import { List, ListItem, ListItemText } from "@material-ui/core";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { blue, red, yellow } from "@material-ui/core/colors";
import { green } from "@material-ui/core/colors";
import { grayColor } from "assets/jss/material-dashboard-pro-react";
import Datetime from "react-datetime";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import ServiceList from "components/CheckBoxAseo/ServiceList.jsx";
import FormGroup from "@material-ui/core/FormGroup";

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const classes = useStyles();

  const [serviciosSanitarios, setServiciosSanitarios] = useState([]);
  const fechaActual = new Date();
  const hoyTimestamp = Math.floor(fechaActual.getTime() / 1000);
  const fechaFormateada = `${fechaActual.getDate()}/${
    fechaActual.getMonth() + 1
  }/${fechaActual.getFullYear()}`;
  const [selectedDate, setSelectedDate] = useState(fechaActual);
  const [selectedHasta, setSelectedHasta] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const [seriesArrayData, setSeriesArrayData] = useState();
  const [seriesTimeArrayData, setTimeSeriesArrayData] = useState();
  const [firmas, setFirmas] = useState();

  const [xaxisData, setXaxisData] = useState();

  const handleCheckboxChange = (id, isChecked) => {
    if (isChecked) {
      setSelectedItems([...selectedItems, id]);
    } else {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    }
  };

  const handleFrom = (e) => {
    setSelectedDate(e);
  };
  const handleHasta = (e) => {
    setSelectedHasta(e);
  };
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  // CARGA DE LA RONDA
  const fetchListaAseo = async () => {
    setOpen(true);
    const result = await axios.get(
      // "http://localhost:5005/api/limpieza/serviciosAgrupados"
      "https://epstool.com/api/limpieza/serviciosAgrupados"
    );
    setServiciosSanitarios(result.data);
    setOpen(false);
  };

  // CARGA DE LA RONDA
  const fetchDataTendencia = async () => {
    setOpen(true);
    // setAuthToken(localStorage.jwtToken);
    try {
      const result = await axios.post(
        // "http://localhost:5005/api/limpieza/tendenciaInformes",
        "https://epstool.com/api/limpieza/tendenciaInformes",
        {
          servicios: selectedItems,
          fechaInicio: selectedDate,
          fechaFinal: selectedHasta,
        }
      );

      const resultFirmas = await axios.post(
        // "http://localhost:5005/api/limpieza/informesPorFirma",
        "https://epstool.com/api/limpieza/informesPorFirma",
        {
          servicios: selectedItems,
          fechaInicio: selectedDate,
          fechaFinal: selectedHasta,
        }
      );
      setFirmas(resultFirmas.data);

      const series = result.data.reduce((acc, curr) => {
        const name = `${curr.nombre} - ${curr.tipo}`;
        if (!acc[name]) {
          acc[name] = [];
        }
        acc[name].push(curr.cantidadDeInformes);
        return acc;
      }, {});

      const seriesArray = Object.keys(series).map((name) => ({
        name: name,
        data: series[name],
      }));

      const seriesTime = result.data.reduce((acc, curr) => {
        const name = `${curr.nombre} - ${curr.tipo}`;
        if (!acc[name]) {
          acc[name] = [];
        }
        acc[name].push(curr.tiempoPromedio);
        return acc;
      }, {});

      const seriesTimeArray = Object.keys(seriesTime).map((name) => ({
        name: name,
        data: seriesTime[name],
      }));

      const xaxis = {
        categories: [...new Set(result.data.map((d) => d.dia))].sort(),
      };
      const options = {
        chart: {
          height: 350,
          type: "line",
        },

        dataLabels: {
          enabled: true,
        },

        xaxis: {
          // type: "datetime",
          categories: xaxis.categories,
          title: {
            text: "Dia/Mes",
          },
        },
      };
      setXaxisData(options);
      setSeriesArrayData(seriesArray);
      setTimeSeriesArrayData(seriesTimeArray);
      // setServiciosSanitarios(result.data);
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  // CARGA INICIAL
  useEffect(() => {
    fetchListaAseo();
  }, []);
  
  return (
    <GridContainer>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card>
        <CardHeader>
          <GridContainer>
            <GridItem xs={12} sm={3}>
              <Typography>Seleccione las fechas a graficar</Typography>
            </GridItem>
            <GridItem xs={12} sm={3}>
              <Datetime
                onChange={handleFrom}
                timeFormat={false}
                inputProps={{ placeholder: "Fecha inicial" }}
              />
            </GridItem>
            <GridItem xs={12} sm={3}>
              <Datetime
                onChange={handleHasta}
                timeFormat={false}
                inputProps={{ placeholder: "Fecha final" }}
              />
            </GridItem>
            <GridItem xs={12} sm={3}>
              <Button
                color="info"
                size="sm"
                onClick={() => fetchDataTendencia()}
              >
                Graficar
              </Button>
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          <GridContainer>
            {seriesArrayData && (
              <GridItem xs={12}>
                <Typography>
                  <strong>
                    Gráfico tendencia sobre cantidad de reportes por día
                  </strong>
                </Typography>
                <Chart
                  options={xaxisData}
                  series={seriesArrayData}
                  type="bar"
                  height={400}
                />
              </GridItem>
            )}

            {seriesTimeArrayData && (
              <GridItem xs={12}>
                <Typography>
                  <strong>
                    Gráfico tendencia sobre tiempo promedio en limpieza del baño
                  </strong>
                </Typography>
                <Chart
                  options={xaxisData}
                  series={seriesTimeArrayData}
                  type="bar"
                  height={400}
                />
              </GridItem>
            )}
          </GridContainer>
          {firmas && (
            <GridContainer>
              {firmas.map((servicio) => (
                <GridItem xs={12} md={4}>
                  <div key={servicio._id} style={{ margin: "0 20px" }}>
                    <h3>
                      Servicio {servicio.servicioData.nombre} -{" "}
                      {servicio.servicioData.tipo}
                    </h3>
                    <h4>Recuento total {servicio.informesTotales} informes</h4>
                    {servicio.firmas.map((firma) => (
                      <p>
                        {firma.firma}: {firma.count}
                      </p>
                    ))}
                  </div>
                </GridItem>
              ))}
            </GridContainer>
          )}
          <div style={{ display: "flex", flexDirection: "row" }}>
            {serviciosSanitarios.map((edificio) => (
              <div key={edificio._id} style={{ margin: "0 20px" }}>
                <h3>{edificio._id}</h3>
                {edificio.niveles.map((nivel) => (
                  <div key={nivel.nivel}>
                    <h4>{nivel.nivel}</h4>
                    <FormGroup>
                      {nivel.servicios.map((servicio) => (
                        <FormControlLabel
                          key={servicio._id}
                          control={
                            <Checkbox
                              onChange={(e) =>
                                handleCheckboxChange(
                                  servicio._id,
                                  e.target.checked
                                )
                              }
                            />
                          }
                          label={`${servicio.nombre} - ${servicio.tipo}`}
                        />
                      ))}
                    </FormGroup>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </CardBody>
      </Card>
    </GridContainer>
  );
}
